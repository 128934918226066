import LazyLoad from 'react-lazyload';

export class TestimonialMessage extends React.Component {


render() {

  const testimonial = this.props.testimonial ? this.props.testimonial : {}


  return  <div className="container">
              <div className="row justify-content-center">
              <div className="col col-sm-10 col-md-9">
                <div className="testimonial-wrapper py-5 px-3  p-sm-5">
              <div className="message text-dark">
                {testimonial.message}
              </div>
              <div className="author-name mt-4">
              <i>- {testimonial.name}</i>
              </div>
              </div>
              </div>

          </div>

  </div>
}

}

export class TestimonialAuthor extends React.Component {


  render() {

    const testimonial = this.props.testimonial

    const currentTestimonial = this.props.currentTestimonial

    let className = "testimonial-author p-2 p-sm-2 p-md-4"
    if (currentTestimonial.name=== testimonial.name) {
      className = className + " active"
    }

    return <div className={className}>
              <a href="#"
              onMouseEnter={(e)=>{e.preventDefault(); this.props.changeTestimonial(this.props.index)}}
              onClick={(e)=>{e.preventDefault(); this.props.changeTestimonial(this.props.index)}}>
              <LazyLoad >
                <img src={testimonial.img} className="img-fluid rounded-circle" alt={'Testimonial from ' + testimonial.name} />
              </LazyLoad>
              </a>
    </div>

  }

}
