import Img from 'react-image'

export default function PhotographerAvatar(props) {

  let profile = props.profile
  let image_width = props.image_width ? parseInt(props.image_width) : 65
  let photographerName = props.name ? props.name : 'Picxy User'

  let profileImgRender = <img className="img-fluid mx-auto rounded-circle" src="https://www.picxy.com/static/img/mysteryman.png" style={{maxWidth:image_width}} alt={"Profile picture of " + photographerName + " on picxy"} />
  if (profile.thumbs) {
    if (profile.thumbs.webp_256 && image_width > 65) {
      profileImgRender = <picture>
          <source srcSet={profile.thumbs.webp_256} type="image/webp" />
          <source srcSet={profile.thumbs.jpeg_256} type="image/jpeg" />
          <img className="rounded-circle" src={profile.thumbs.jpeg_256} style={{maxWidth:image_width}} alt={"Profile picture of " + photographerName + " on picxy"} />
        </picture>
    }
    else {
      profileImgRender = <picture>
          <source srcSet={profile.thumbs.webp_65} type="image/webp" />
          <source srcSet={profile.thumbs.jpeg_65} type="image/jpeg" />
          <img className="rounded-circle" src={profile.thumbs.jpeg_65} style={{maxWidth:image_width}} alt={"Profile picture of " + photographerName + " on picxy"} />
        </picture>
    }
  }
  else if (profile.thumbnail_small) {
    profileImgRender = <img className="rounded-circle" src={profile.thumbnail_small} style={{maxWidth:image_width}} alt={"Profile picture of " + photographerName + " on picxy"} />
  }
  return <div>{profileImgRender}</div>
}
