import React from 'react'
import Link from 'next/link'
import PImage from './pImage'
import {setLocalKeyValue, getLocalKeyValue, getJsonData, setJsonData} from '../storage'
import Favorite from './favorite'
import VisibilitySensor from 'react-visibility-sensor'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons'
export default class ListVideo extends React.Component {

  formatVideoLength(length){
    const d = new Date(null)
    d.setSeconds(length)
    return d.toISOString().substr(14, 5)
  }

  render() {
    let video = this.props.video
    let cols = this.props.cols || 3
    let classname_requried = this.props.classname_requried?false:true
    let content
    if(video) {

      let title = video.title + " video by " + video.photographer.username

      content = <div className="photo-wrapper"><Link as={`/video/${video.slug}`} href={"/video?id="+video.id}>
              <a href={`/video/${video.slug}`} title={title} >
                <div className="img-holder">
                  <PImage key={video.id} url={video.thumbs.jpeg_800} title={title} />
                </div>
                <div className="position-absolute text-white" style={{bottom:0, left:5}}>
                  <FontAwesomeIcon icon={faPlayCircle} /> {video.length ? this.formatVideoLength(video.length): ""}
                </div>
              </a>
            </Link>

          </div>
    }
    let className = classname_requried?((cols === 3) ? "col-sm-6 col-md-4 mb-4" : "col-sm-6 mb-4"):""

    // if("medium" === this.props.size) {
    //   className= "col-sm-6 col-md-3 col-lg-2 mb-4"
    // }

    return <div className={className}>
            {content}
    </div>
  }
}
