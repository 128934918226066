import {ASSET_PROFILE_URL} from '../vars'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import ShowMoreText from 'react-show-more-text'
import PhotographerAvatar from '../components/photographerAvatar'


export default function ContributorInfo(props){


  let profile
  if(props.contributor_info){
    const stats = props.contributor_info.stats
    const contributor = props.contributor_info.contributor

    let contributorName = contributor.first_name + ' ' + contributor.last_name

    if(!contributorName) {
      contributorName = contributor.username
    }

    let profileImgRender = <PhotographerAvatar profile={contributor.profile} image_width='165' name={contributorName}/>
    let photo_count = stats.photos ? <div className="text-center"><div><strong>{stats.photos ? stats.photos: 0}</strong></div><div>{stats.photos > 1 ? "Photos": "Photo"}</div></div>: ""
    let video_count = stats.videos ? <li className="list-inline-item"><div className="ml-3 text-center"><div><strong>{stats.videos}</strong></div> <div>{stats.videos > 1 ? "Videos": "Video"}</div></div></li> : ""
    let like_count = stats.likes ? <li className="list-inline-item"><div className="ml-3 text-center"><div><strong>{stats.likes}</strong></div> <div>{stats.likes > 1 ? "Likes": "Like"}</div></div></li> : ""
    let view_count = stats.views ? <li className="list-inline-item"><div className="ml-3 text-center"><div><strong>{stats.views ? stats.views: 0}</strong></div><div>{stats.views > 1 ? "Views": "View"}</div></div></li>: ""

     profile = <div className="container mb-5 mt-5">
         <div className="row justify-content-center">
             <div className="col-md-10">
             <div className="row align-items-center ">
             <div className="col-sm-4 text-center text-sm-end align-middle mb-3 mb-sm-0">
                 <h1 className=" font-weight-light mb-2">{contributorName}</h1>
                 <div className="mb-2">{contributor.profile.location?<FontAwesomeIcon icon={faMapMarkerAlt}/>:""} {contributor.profile.location}</div>
                 <div className="">Member since {moment(contributor.date_joined).toNow(true)}</div>
             </div>
             <div className="col-sm-4 mb-3 mb-sm-0">
               <div className="text-center">
                 {profileImgRender}
               </div>
             </div>
             <div className="col-sm-4 text-center text-sm-left ">

                 <div className="mb-2">
                   <ShowMoreText lines={5} more='Know more' >{contributor.profile.bio}</ShowMoreText>
                 </div>
                 <div className="mb-2">
                   <ul className="list-inline">
                     <li className="list-inline-item">
                     {photo_count}
                     </li>

                     {video_count}

                     {like_count}
                     {view_count}

                   </ul>
                  </div>
             </div>
             </div>
             </div>
         </div>
     </div>
  }

  return  <>
{profile}
  </>

}
