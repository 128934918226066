import dynamic from 'next/dynamic';
import LayoutBasic from '../layouts/layoutBasic'
import { useEffect, useState, useRef } from 'react'
// import $ from "jquery";
import CollectionPreviewSingle from '../components/collectionPreviewSingle'
import { SeoMetaIndex } from '../components/seoMeta'
import NavPure from '../components/navPure'
import Clients from '../components/clients'
import { logEvent } from '../components/analytics'
import { UseHorizontalScroll } from '../components/useScroll'
import { SearchComp } from '../components/searchBar'
import AZ from '../components/azCollections'
import Link from 'next/link'
import PhotoListHolder from '../components/photoListHolder'
import { TestimonialMessage, TestimonialAuthor } from '../components/testimonial'
import ListPhoto from '../components/listPhoto';
import checkIsOutsideIndia from './checkIsOutsideIndia'
import PImage from './pImage';
import slugify from 'slugify'
import Favorite from './favorite';
import { GET } from '../helpers'
import { OverlayTrigger } from 'react-bootstrap';
import Axios from 'axios';
import { QUICK_ACCESS_COLLECTIONS } from '../vars';
//import "owl.carousel"; // You can keep JS libraries




function MorePicxy(props) {

  const [seeMore, setSeeMore] = useState(false);



  let seeMoreBtn = <a href="#" className="py-2 d-block" onClick={(e) => { e.preventDefault(); setSeeMore(!seeMore) }}>{seeMore ? "See Less" : "See More"}</a>

  return <div className="container">

    <div className="h3 text-center mb-4">Begin your journey with Picxy today</div>
    <div className="small">
      Picxy is the Largest Stock Photo Company in South Asia aiming to provide you with authentic images exclusively, pertaining to South Asian Context as a whole. We are leaders in offering imagery of South Asia but have already set out to expand to every part of the continent! Browse from one of the most affordable Indian stock photo websites with an endless inventory of diverse illustrations and footages and bid adieu to copyright issues forever!
    </div>
    <div className={seeMore ? "" : "d-none"}>

      <div className="h4 mt-4">Why Are Our Images Important for your Business?</div>
      <div className="small">
        Creativity is the most important aspect that grabs more attention to your work. Your website may hold beneficial and interesting written content for your audience, but to what extent can you hold their attention without appealing visual content? Not convinced? Here are some stats to prove:
        <div>*For starters, content that has some of the other visual receives <a href="https://www.searchenginejournal.com/effectively-use-visual-storytelling-infographic/136436/" target="_blank" rel="nofollow">94 percent more views</a></div>
        <div>*On Twitter, tweets with images or videos get an approximately 150 percent increase in retweets respectively.</div>
        <div><a href="https://www.searchenginejournal.com/google-reduces-star-rating-threshold-businesses-take-notice/185947/" target="_blank" rel="nofollow">*90 percent of online shoppers</a> read reviews to peer into the quality of a product or service before purchasing.</div>
      </div>
      <div className="h4 mt-4">Royalty-Free Images for the Quality you deserve</div>
      <div className="small">
        Using Images or any other content that you didn’t create requires you to obtain specific permission from the copyright owner. And therefore, choosing royalty-free images is the best option for you to avoid any copyright infringement issues later. With flexible plans that suit your needs, we aim to provide high definition royalty-free images that wouldn’t burn a hole in your pocket!
      </div>
      <div className="h4 mt-4">We are Vocal for Local!</div>
      <div className="small">
        Being a proud organization based in India, we do our part in supporting and promoting local products to the global community. We have provided our Indian Photographers the best platform to showcase their talent and improve their skills with a reviewing team set up just for them. And by giving an impetus to the growth of our local cohort, we aim to make self-reliance (Atma-Nirbharta) the mantra of our business.
      </div>
      <div className="h4 mt-4"><Link href="/collections"><a>Our Collections</a></Link></div>
      <div className="small">
        Hitting 50,000 images within the initial six months of launch, we have been able to gather a voluminous compilation of high-resolution images available for commercial use at a price as low as ₹100. Generically, we can categorize our hand-curated collections into different types like festivals, events, places, causes, etc. To add on to that, we cover India as a country - its culture, rituals, diversity, and never-seen-before images of some appalling places. Explore India and its heritage through the eyes of our deft photographers on-board with our amazing collection!
      </div>

    </div>

    {seeMoreBtn}

  </div>
}
//const OwlCarousel = dynamic(() => import('owl.carousel'), { ssr: false });
//dynamic(() => import('owl.carousel'), { ssr: false });




export default function Home(props) {

  const [testimonialIndex, setTestimonialIndex] = useState(0);
  const [isOutsideIndia, setOutsideIndia] = useState(null);
  const [showMessage, setShowMessage] = useState(false);
  const [alphabet, setAlphbet] = useState();



  const changeTestimonial = (index) => {
    setTestimonialIndex(index)
  }

  // useEffect(() => {
  //   // let { longitude, latitude } = props;
  //   // const isOutsideIndia = checkIsOutsideIndia(latitude, longitude);
  //   // setOutsideIndia(isOutsideIndia);
  // }, []);

  const handleClick = () => {
    setShowMessage(prevState => !prevState);  // Toggles the state
  };

  useEffect(() => {
    // Ensure OwlCarousel is initialized after jQuery is loaded
    const initializeOwlCarousel = () => {
      if (typeof window !== 'undefined' && typeof $ !== 'undefined') {
        $("#testimonial-slider").owlCarousel({
          items: 2,
          pagination: false,
          slideSpeed: 1000,
          autoPlay: true,
          nav: true,
          dots: false,
          responsive: {
            0: {
              items: 1
            },
            600: {
              items: 1
            },
            1000: {
              items: 1
            }
          }
        });
      } else {

        console.error('jQuery or Owl Carousel not loaded');
      }
    };




    const Carousel2 = () => {
      if (typeof window !== 'undefined' && typeof $ !== 'undefined') {
        $("#clients-slider").owlCarousel({
          items: 1,
          itemsDesktop: [1000, 2],
          itemsDesktopSmall: [990, 2],
          itemsTablet: [768, 1],
          pagination: true,
          navigation: false,
          navigationText: ["", ""],
          slideSpeed: 1000,
          autoPlay: true
        });
      }
    }

    // Initialize owlCarousel when the component is mounted (client-side)
    if (typeof window !== 'undefined') {
      // Check if Owl Carousel is available
      const script = document.createElement('script');
      script.src = "https://cdnjs.cloudflare.com/ajax/libs/owl-carousel/1.3.3/owl.carousel.min.js";
      script.onload = initializeOwlCarousel;
      // script.onload = Carousel2;

      document.body.appendChild(script);
    }


    if (typeof window !== 'undefined') {
      // Check if Owl Carousel is available
      const script = document.createElement('script');
      script.src = "https://cdnjs.cloudflare.com/ajax/libs/owl-carousel/1.3.3/owl.carousel.min.js";
      // script.onload = initializeOwlCarousel;
      script.onload = Carousel2;

      document.body.appendChild(script);
    }
    getAlphabet();



  }, []);

  let likeIds = []
  if (props.likes) {
    likeIds = props.likes
  }

  let testimonials = props.testimonials
  let interestedTestimonial = testimonials[testimonialIndex]

  // let testimonialAuthors = testimonials.map((testimonial, index) => {

  //   return <TestimonialAuthor key={index} testimonial={testimonial}
  //     changeTestimonial={changeTestimonial} index={index} currentTestimonial={interestedTestimonial} />
  // })

  // const ImageGrid = ({ data }) => {
  //   return (
  //     <section className="section">
  //       <h3 className="main-title">Elevate Your Projects with Our <br /> Beautiful Stock Photography</h3>
  //       {/* Photo Grid starts */}
  //       <div className="col-xl-12 col-md-12 col-sm-12 photography-grid">
  //         <div className="stock-img-col">
  //           <div className="subdiv">
  //             <img src={data.thumbs.webp_700} style={{ width: '100%' }} alt={data.title} />
  //             <span className="coll">Portrait</span>
  //             <span className="coll1">{data.title}</span>
  //           </div>
  //           {/* You can add more images here or loop through additional images */}
  //         </div>
  //       </div>
  //     </section>
  //   );
  // };

  // const friendly_url = false
  // const titlePrefix = "Image of "
  // let content = props.photos.map((photo, id) => {
  //   const liked = (likeIds.indexOf(photo.id) > -1)
  //  return <ListPhoto titlePrefix={"Image of "} friendly_url={friendly_url} size="medium" key={id} photo={photo} liked={liked} reference={props.router.asPath} />


  // })


  // let context_list = <PhotoListHolder>
  //   {content}
  // </PhotoListHolder>

  const featured = props.featured

  const collections = props.collections


  // let collectionRender
  // if (collections && collections.length) {
  //   collectionRender = collections.map((collection, index) => {


  //     return <CollectionPreviewSingle key={index} collection={collection} likeIds={likeIds} />
  //   })
  // }

  async function getAlphabet() {
    await fetch(QUICK_ACCESS_COLLECTIONS)?.then(res => res?.json())?.then(response => setAlphbet(response));
  }

  let h1String = "South Asia's Largest Stock Photos"


  return <LayoutBasic heavyFont={true}
    defaultNavBar={true}
    title="Indian stock photo website|Explore Indian stock Images from photographers across India" {...props}>
    <SeoMetaIndex {...props} />
    {/* <header> */}
    {/* <div className="big-background" style={{ backgroundImage: "url(" + featured.thumbs['jpeg_1920'] + ")" }}> */}
    {/* <div className="big-background" style={{ backgroundImage: "url( https://images.picxy.com/cache/2023/5/29/6b9af87dfff0b8fdc34c7340c167a9ce.jpg )" }}>  */}
    <section className="banner">

      <div id="overlay">

        <NavPure token={props.token} logged_in_user={props.user} {...props} collections={collections} />

        <div className="big-head">
          <div className="text1head">
            <h3>{h1String}</h3>
          </div>

          {/* <div className=""> */}
          <SearchComp />
          {/* </div> */}


          {/*
        <div className="head-pic-info text-center text-white">
          Shot by Picxy Originals
          <b>
                <Link as={`/${featured.shot_by_username}`} href={"/?username=" + featured.shot_by_username}>
                  <a className="text-white" href={`/${featured.shot_by_username}`} title={"View photos of " + featured.shot_by_username}>{featured.shot_by}</a>
                </Link></b>
        </div>*/}
        </div>


      </div>
    </section>
    {/* </header> */}

    <main>
      {/* <section className="pt-2 pb-5">
        <div className="container mt-5 mb-4">
          <h2 className="text-center">Featured Stock Image Collections</h2>
        </div>
        <div className="container">
          <div className="row">
            {collectionRender}
          </div>
        </div>


      </section> */}

      {/* <!--- Grid Starts----> */}



      <section className="collections-block">
        <div className="container">
          <div className="row handpicked-blog">
            <div className="col-lg-6 col-md-12 mb-4 mb-lg-0">
              <div className="subdiv pe-lg-5">
                <h3 className="headh3 mt-4 mt-md-0">
                  Explore Our Handpicked Stunning Collections For Your Visual Content
                </h3>
                <p className="headp mb-0 mb-lg-5 pb-3">
                  Creativity is the most important aspect that grabs more attention to your work. Your website may hold beneficial and interesting written content for your audience.
                </p>
              </div>

              <div className="mb-lg-0">

                <div className="row">

                  <div className="col-lg-6 col-sm-6">
                    <div className="subdiv img-card collection1" style={{ cursor: "pointer" }}>
                      <img src="../static/img_new/more_img_icon.svg" className="copy" alt="Icon" />
                      <div className='d-flex img-hover'>
                        {collections.slice(0, 1).map((collection, index) => {

                          return (
                            <>
                              {collection.photos.map((photo, photoIndex) => {

                                return (
                                  <>
                                    <Link as={`/collections/${collection.slug}`} href={"/collections?collection_slug=" + collection.slug} key={index}>

                                      <div
                                        key={photoIndex} // Added key for each div
                                        style={{
                                          backgroundImage: `url(${photo.thumbnail_large})`,
                                          backgroundSize: 'cover', // This ensures the image covers the entire div
                                          backgroundPosition: 'center', // Centers the image
                                          backgroundRepeat: 'no-repeat', // Ensures the image doesn't repeat
                                        }}
                                        alt={`Photo from collection ${collection.slug}`}
                                      >
                                        {/* You can add any child content here if needed */}
                                      </div>


                                    </Link>


                                  </>

                                )
                              })}
                            </>
                          );
                        })}



                      </div>
                      {collections.slice(0, 1).map((collection, index) => {
                        if (index === 0) {
                          return (
                            <Link href={`/collections/${collection.slug}`} key={index}>
                              <a className="texthigh">
                                {collection.slug}
                                <span style={{ fontWeight: "bold", color: "white" }}>&#8594;</span>
                              </a>
                            </Link>
                          );
                        }
                        return null; // Do not render anything for other elements
                      })}

                    </div>

                  </div>

                  <div class="col-lg-6 col-sm-6">
                    <div className="subdiv img-card collection2" style={{ cursor: "pointer" }}>
                      <img src="../static/img_new/more_img_icon.svg" className="copy" alt="Icon" />
                      <div className='d-flex img-hover'>

                        {collections.slice(1, 2).map((collection, index) => {
                          return (
                            <>

                              {collection.photos.map((photo, photoIndex) => {
                                return (
                                  <>
                                    <Link
                                      as={`/collections/${collection.slug}`}
                                      href={`/collections?collection_slug=${collection.slug}`}
                                      key={photo.id} // Ensure a unique key for the Link
                                    >
                                      <div
                                        key={photoIndex} // Added key for each div
                                        style={{
                                          backgroundImage: `url(${photo.thumbnail_large})`,
                                          backgroundSize: 'cover', // This ensures the image covers the entire div
                                          backgroundPosition: 'center', // Centers the image
                                          backgroundRepeat: 'no-repeat', // Ensures the image doesn't repeat
                                        }}
                                        alt={`Photo from collection ${collection.slug}`}
                                      >
                                        {/* You can add any child content here if needed */}
                                      </div>

                                    </Link>
                                  </>
                                )
                              })}
                            </>
                          );
                        })}


                      </div>
                      {collections.slice(1, 2).map((collection, index) => {

                        if (index === 0) {
                          return (
                            <Link href={`/collections/${collection.slug}`} key={index}>
                              <a className="texthigh">
                                {collection.slug}
                                <span style={{ fontWeight: "bold", color: "white" }}>&#8594;</span>
                              </a>
                            </Link>
                          );
                        }
                        return null; // Do not render anything for other elements
                      })}
                    </div>

                  </div>

                  {/* </div> */}

                </div>
              </div>
            </div>



            <div className="col-lg-3 col-md-6 mb-4 mb-lg-0 d-md-flex d-lg-block gap-md-4">

              <div className="subdiv img-card mb-4 collection3" style={{ cursor: "pointer" }}>

                <img src="../static/img_new/more_img_icon.svg" className="copy" alt="Icon" />
                <div className='d-flex img-hover'>

                  {collections.slice(2, 3).map((collection, index) => {
                    return (
                      <>
                        {collection.photos.map((photo, photoIndex) => {

                          return (
                            <>
                              <Link as={`/collections/${collection.slug}`} href={"/collections?collection_slug=" + collection.slug} key={index}>

                                <div
                                  key={photoIndex} // Added key for each div
                                  style={{
                                    backgroundImage: `url(${photo.thumbnail_large})`,
                                    backgroundSize: 'cover', // This ensures the image covers the entire div
                                    backgroundPosition: 'center', // Centers the image
                                    backgroundRepeat: 'no-repeat', // Ensures the image doesn't repeat
                                  }}
                                  alt={`Photo from collection ${collection.slug}`}
                                >
                                  {/* You can add any child content here if needed */}
                                </div>

                              </Link>
                            </>
                          )
                        })}
                      </>
                    );
                  })}
                </div>

                {collections.slice(2, 3).map((collection, index) => {
                  if (index === 0) {
                    return (
                      <Link href={`/collections/${collection.slug}`} key={index}>
                        <a className="texthigh">
                          {collection.slug}
                          <span style={{ fontWeight: "bold", color: "white" }}>&#8594;</span>
                        </a>
                      </Link>
                    );
                  }
                  return null; // Do not render anything for other elements
                })}

              </div>

              <div className="subdiv img-card mb-md-4 collection4" style={{ cursor: "pointer" }}>
                <img src="../static/img_new/more_img_icon.svg" className="copy" alt="Icon" />
                <div className='d-flex img-hover'>
                  {collections.slice(3, 4).map((collection, index) => (

                    <>
                      {collection.photos.map((photo, photoIndex) => {
                        return (
                          <>
                            <Link as={`/collections/${collection.slug}`} href={"/collections?collection_slug=" + collection.slug} key={index}>

                              <div
                                key={photoIndex} // Added key for each div
                                style={{
                                  backgroundImage: `url(${photo.thumbnail_large})`,
                                  backgroundSize: 'cover', // This ensures the image covers the entire div
                                  backgroundPosition: 'center', // Centers the image
                                  backgroundRepeat: 'no-repeat', // Ensures the image doesn't repeat
                                }}
                                alt={`Photo from collection ${collection.slug}`}
                              >
                                {/* You can add any child content here if needed */}
                              </div>


                            </Link>

                          </>
                        )
                      })}

                    </>

                  ))}
                </div>



                {collections.slice(3, 4).map((collection, index) => {
                  if (index === 0) {
                    return (
                      <Link href={`/collections/${collection.slug}`} key={index}>
                        <a className="texthigh">
                          {collection.slug}
                          <span style={{ fontWeight: "bold", color: "white" }}>&#8594;</span>
                        </a>
                      </Link>
                    );
                  }
                  return null; // Do not render anything for other elements
                })}
              </div>



            </div>



            <div className="col-lg-3 col-md-6 mb-4 mb-lg-0 d-md-flex d-lg-block gap-md-4">

              <div className="subdiv img-card mb-4 collection5" style={{ cursor: "pointer" }}>

                <img src="../static/img_new/more_img_icon.svg" className="copy" alt="Icon" />

                <div className='d-flex img-hover'>
                  {collections.slice(4, 5).map((collection, index) => (
                    <>
                      {collection.photos.map((photo, photoIndex) => {
                        return (
                          <>
                            <Link as={`/collections/${collection.slug}`} href={"/collections?collection_slug=" + collection.slug} key={index}>

                              <div
                                key={photoIndex} // Added key for each div
                                style={{
                                  backgroundImage: `url(${photo.thumbnail_large})`,
                                  backgroundSize: 'cover', // This ensures the image covers the entire div
                                  backgroundPosition: 'center', // Centers the image
                                  backgroundRepeat: 'no-repeat', // Ensures the image doesn't repeat
                                }}
                                alt={`Photo from collection ${collection.slug}`}
                              >
                                {/* You can add any child content here if needed */}
                              </div>


                            </Link >
                          </>
                        )
                      })}
                    </>
                  ))}
                </div>



                {collections.slice(4, 5).map((collection, index) => {
                  if (index === 0) {
                    return (
                      <Link href={`/collections/${collection.slug}`} key={index}>
                        <a className="texthigh">
                          {collection.slug}
                          <span style={{ fontWeight: "bold", color: "white" }}>&#8594;</span>
                        </a>
                      </Link>
                    );
                  }
                  return null; // Do not render anything for other elements
                })}
              </div>


              <div className="subdiv img-card mb-4 collection6" style={{ cursor: "pointer" }}>
                <img src="../static/img_new/more_img_icon.svg" className="copy" alt="Icon" />
                <div className='d-flex img-hover'>
                  {collections.slice(5, 6).map((collection, index) => (
                    <>
                      {collection.photos.map((photo, photoIndex) => {
                        return (
                          <>
                            <Link as={`/collections/${collection.slug}`} href={"/collections?collection_slug=" + collection.slug} key={index}>

                              <div
                                key={photoIndex} // Added key for each div
                                style={{
                                  backgroundImage: `url(${photo.thumbnail_large})`,
                                  backgroundSize: 'cover', // This ensures the image covers the entire div
                                  backgroundPosition: 'center', // Centers the image
                                  backgroundRepeat: 'no-repeat', // Ensures the image doesn't repeat
                                }}
                                alt={`Photo from collection ${collection.slug}`}
                              >
                                {/* You can add any child content here if needed */}
                              </div>


                            </Link >

                          </>
                        )
                      })}
                    </>
                  ))}
                </div>

                {collections.slice(5, 6).map((collection, index) => {
                  if (index === 0) {
                    return (
                      <Link href={`/collections/${collection.slug}`} key={index}>
                        <a className="texthigh">
                          {collection.slug}
                          <span style={{ fontWeight: "bold", color: "white" }}>&#8594;</span>
                        </a>
                      </Link>
                    );
                  }
                  return null; // Do not render anything for other elements
                })}
              </div>
            </div>
          </div>
        </div>
      </section>




      {/* <!--- Grid Ends----> */}



      {/* <section className="pt-2 pb-5">
        <div className="container mb-4">
          <h2 className="text-center">Featured Stock Photos</h2>
        </div>
        <PhotoListHolder>
          {content}
        </PhotoListHolder>
        <div className="text-center mt-3">
          <Link href="/photos">
            <a className="btn btn-primary btn-primary btn-rounded">Explore More Photos</a>
          </Link>
        </div>
      </section> */}

      {/* <!---Collection Starts----> */}

      <section className="section">
        <h3 className="main-title">
          Elevate Your Projects with Our <br className='d-none d-lg-block' /> Beautiful Stock Photography
        </h3>
        {/* Photo Grid starts */}
        <div className="photography-grid">
          <div className="stock-img-col col1">
            {props.photos.slice(0, 2).map((photo, index) => {
              const liked = (likeIds.indexOf(photo.id) > -1);
              let like_btn = <Favorite liked={liked} photo={photo} reference={props.router.asPath}></Favorite>;
              let friendly_url = false;

              return (
                <div className="subdiv photo-wrapper" key={index}>
                  <Link
                    as={friendly_url ? `/photo/${photo.id + '-' + slugify(photo.title)}` : `/photo/${photo.id}`}
                    href={"/photo?id=" + photo.id}>

                    <a href={friendly_url ? `/photo/${photo.id + '-' + slugify(photo.title)}` : `/photo/${photo.id}`} rel="noopener noreferrer"
                      style={{
                        display: 'block', // Allows you to control the dimensions
                        width: '100%', // Sets the width to 100% of the container
                        height: '100%', // You can adjust the height as needed
                        backgroundImage: `url(${photo.thumbnail_large})`,
                        backgroundSize: 'cover', // Ensures the image covers the container
                        backgroundPosition: 'center', // Centers the background image
                        cursor: 'pointer',
                      }}
                    >
                      <span className="coll">{photo.title}</span>
                      <span className="picxy-fav-btn" style={{ marginTop: '15px' }}>{like_btn}</span>
                    </a>
                  </Link>
                </div>
              );
            })}

            <div className='subdiv photo-wrapper m-0' style={{ display: 'flex', justifyContent: 'space-between' }}>
              {props.photos.slice(2, 4).map((photo, index) => {
                const liked = (likeIds.indexOf(photo.id) > -1);
                let like_btn = <Favorite liked={liked} photo={photo} reference={props.router.asPath}></Favorite>;
                let friendly_url = false;

                return (
                  <div
                    className="subdiv d-flex gap-3 photo-wrapper"
                    key={index}
                    style={{ width: 'calc(50% - 5px)', marginRight: index === 0 ? '10px' : '0' }}
                  >
                    <Link
                      as={friendly_url ? `/photo/${photo.id + '-' + slugify(photo.title)}` : `/photo/${photo.id}`}
                      href={"/photo?id=" + photo.id}>


                      <a href={friendly_url ? `/photo/${photo.id + '-' + slugify(photo.title)}` : `/photo/${photo.id}`} rel="noopener noreferrer"
                        style={{
                          display: 'block', // Allows you to control the dimensions
                          width: '100%', // Sets the width to 100% of the container
                          height: '100%', // You can adjust the height as needed
                          backgroundImage: `url(${photo.thumbnail_large})`,
                          backgroundSize: 'cover', // Ensures the image covers the container
                          backgroundPosition: 'center', // Centers the background image
                          cursor: 'pointer',
                        }}
                      >
                        <span className="coll" style={{ display: 'block' }} >{photo.title}</span>
                        <span className="picxy-fav-btn" style={{ marginTop: '15px' }}>{like_btn}</span>
                      </a>
                    </Link>
                  </div>
                );
              })}
            </div>



          </div>

          <div className="stock-img-col col2">
            {props.photos.slice(4, 6).map((photo, index) => {
              const liked = (likeIds.indexOf(photo.id) > -1)
              let like_btn = <Favorite liked={liked} photo={photo} reference={props.router.asPath}></Favorite>
              let friendly_url = false;
              return (
                <div className="subdiv photo-wrapper" key={index}>
                  <Link
                    as={friendly_url ? `/photo/${photo.id + '-' + slugify(photo.title)}` : `/photo/${photo.id}`}
                    href={"/photo?id=" + photo.id}>


                    <a href={friendly_url ? `/photo/${photo.id + '-' + slugify(photo.title)}` : `/photo/${photo.id}`} rel="noopener noreferrer"
                      style={{
                        display: 'block', // Allows you to control the dimensions
                        width: '100%', // Sets the width to 100% of the container
                        height: '100%', // You can adjust the height as needed
                        backgroundImage: `url(${photo.thumbnail_large})`,
                        backgroundSize: 'cover', // Ensures the image covers the container
                        backgroundPosition: 'center', // Centers the background image
                        cursor: 'pointer',
                      }}
                    >

                      <span className="coll">{photo.title}</span>
                      <span className="picxy-fav-btn" style={{ marginTop: '15px' }}>{like_btn}</span>
                    </a>
                  </Link>
                </div>
              )
            })}
          </div>

          <div className="stock-img-col col3">
            <div className='subdiv photo-wrapper m-0' style={{ display: 'flex', justifyContent: 'space-between' }}>
              {props.photos.slice(6, 8).map((photo, index) => {
                const liked = (likeIds.indexOf(photo.id) > -1);
                let like_btn = <Favorite liked={liked} photo={photo} reference={props.router.asPath}></Favorite>;
                let friendly_url = false;

                return (
                  <div
                    className="subdiv d-flex gap-3"
                    key={index}
                    style={{ width: 'calc(50% - 5px)', marginRight: index === 0 ? '10px' : '0' }}
                  >
                    <a href={friendly_url ? `/photo/${photo.id + '-' + slugify(photo.title)}` : `/photo/${photo.id}`} rel="noopener noreferrer"
                      style={{
                        display: 'block', // Allows you to control the dimensions
                        width: '100%', // Sets the width to 100% of the container
                        height: '100%', // You can adjust the height as needed
                        backgroundImage: `url(${photo.thumbnail_large})`,
                        backgroundSize: 'cover', // Ensures the image covers the container
                        backgroundPosition: 'center', // Centers the background image
                        cursor: 'pointer',
                      }}
                    >
                      <span className="coll" style={{ display: 'block' }} >{photo.title}</span>
                      <span className="picxy-fav-btn" style={{ marginTop: '15px' }}>{like_btn}</span>
                    </a>
                  </div>
                );
              })}
            </div>

            {props.photos.slice(8, 10).map((photo, index) => {
              const liked = (likeIds.indexOf(photo.id) > -1)
              let like_btn = <Favorite liked={liked} photo={photo} reference={props.router.asPath}></Favorite>
              let friendly_url = false;
              return (

                <div className="subdiv photo-wrapper" key={index}>



                  <a href={friendly_url ? `/photo/${photo.id + '-' + slugify(photo.title)}` : `/photo/${photo.id}`} rel="noopener noreferrer"
                    style={{
                      display: 'block', // Allows you to control the dimensions
                      width: '100%', // Sets the width to 100% of the container
                      height: '100%', // You can adjust the height as needed
                      backgroundImage: `url(${photo.thumbnail_large})`,
                      backgroundSize: 'cover', // Ensures the image covers the container
                      backgroundPosition: 'center', // Centers the background image
                      cursor: 'pointer',
                    }}
                  >
                    <span className="coll">{photo.title}</span>
                    <span className="picxy-fav-btn" style={{ marginTop: '15px' }}>{like_btn}</span>
                  </a>
                </div>
              )
            })}
          </div>

          <div className="stock-img-col col4">
            {props.photos.slice(10, 13).map((photo, index) => {
              const liked = (likeIds.indexOf(photo.id) > -1)
              let like_btn = <Favorite liked={liked} photo={photo} reference={props.router.asPath}></Favorite>
              let friendly_url = false;
              return (

                <div className="subdiv photo-wrapper" key={index}>
                  <Link
                    as={friendly_url ? `/photo/${photo.id + '-' + slugify(photo.title)}` : `/photo/${photo.id}`}
                    href={"/photo?id=" + photo.id}>

                    <a href={friendly_url ? `/photo/${photo.id + '-' + slugify(photo.title)}` : `/photo/${photo.id}`} rel="noopener noreferrer"
                      style={{
                        display: 'block', // Allows you to control the dimensions
                        width: '100%', // Sets the width to 100% of the container
                        height: '100%', // You can adjust the height as needed
                        backgroundImage: `url(${photo.thumbnail_large})`,
                        backgroundSize: 'cover', // Ensures the image covers the container
                        backgroundPosition: 'center', // Centers the background image
                        cursor: 'pointer',
                      }}
                    >
                      <span className="coll">{photo.title}</span>
                      <span className="picxy-fav-btn" style={{ marginTop: '15px' }}>{like_btn}</span>
                    </a>
                  </Link>
                </div>
              )
            })}
          </div>

          <div className="stock-img-col col5">


            {props.photos.slice(13, 15).map((photo, index) => {
              const liked = (likeIds.indexOf(photo.id) > -1)
              let like_btn = <Favorite liked={liked} photo={photo} reference={props.router.asPath}></Favorite>
              let friendly_url = false;
              return (

                <div className="subdiv photo-wrapper" key={index}>
                  <Link
                    as={friendly_url ? `/photo/${photo.id + '-' + slugify(photo.title)}` : `/photo/${photo.id}`}
                    href={"/photo?id=" + photo.id}>

                    <a href={friendly_url ? `/photo/${photo.id + '-' + slugify(photo.title)}` : `/photo/${photo.id}`} rel="noopener noreferrer"
                      style={{
                        display: 'block', // Allows you to control the dimensions
                        width: '100%', // Sets the width to 100% of the container
                        height: '100%', // You can adjust the height as needed
                        backgroundImage: `url(${photo.thumbnail_large})`,
                        backgroundSize: 'cover', // Ensures the image covers the container
                        backgroundPosition: 'center', // Centers the background image
                        cursor: 'pointer',
                      }}
                    >

                      <span className="coll">{photo.title}</span>
                      <span className="picxy-fav-btn" style={{ marginTop: '15px' }}>{like_btn}</span>
                    </a>
                  </Link>
                </div>
              )
            })}


            <div className='subdiv' style={{ display: 'flex', justifyContent: 'space-between' }}>
              {props.photos.slice(15, 17).map((photo, index) => {
                const liked = (likeIds.indexOf(photo.id) > -1);
                let like_btn = <Favorite liked={liked} photo={photo} reference={props.router.asPath}></Favorite>;
                let friendly_url = false;

                return (
                  <div
                    className="subdiv photo-wrapper"
                    key={index}
                    style={{ width: 'calc(50% - 5px)', marginRight: index === 0 ? '10px' : '0' }}
                  >
                    <Link
                      as={friendly_url ? `/photo/${photo.id + '-' + slugify(photo.title)}` : `/photo/${photo.id}`}
                      href={"/photo?id=" + photo.id}>

                      <a href={friendly_url ? `/photo/${photo.id + '-' + slugify(photo.title)}` : `/photo/${photo.id}`} rel="noopener noreferrer"
                        style={{
                          display: 'block', // Allows you to control the dimensions
                          width: '100%', // Sets the width to 100% of the container
                          height: '100%', // You can adjust the height as needed
                          backgroundImage: `url(${photo.thumbnail_large})`,
                          backgroundSize: 'cover', // Ensures the image covers the container
                          backgroundPosition: 'center', // Centers the background image
                          cursor: 'pointer',
                        }}
                      >
                        <span className="coll" style={{ display: 'block' }} >{photo.title}</span>
                        <span className="picxy-fav-btn" style={{ marginTop: '15px' }}>{like_btn}</span>
                      </a>
                    </Link>
                  </div>
                );
              })}
            </div>

          </div>

        </div>
      </section>

      <section class="container-fluid subdiv">
        <div class="btnexp">
          <Link href="/photos">
            <button class="custom-btn">Explore More </button>
          </Link>
        </div>
      </section>
      {/* <!---Collection Ends----> */}

      {/* <!---Section1 Starts----> */}
      <section className="container">
        <div className="row py-90">
          <div className="col-xl-6 col-md-6 col-sm-12 section1">
            <img src="../static/img_new/textimg.png" alt="Snow" style={{ width: '100%' }} />
          </div>
          <div className="col-xl-6 col-md-6 col-sm-12 section1 sectpad">
            <h3 className="section1h3">Why Are Our<br className='d-none d-md-block' /> Images Important <br className='d-none d-md-block' />for your Business?</h3>
            <p className="section1p mb-4">Creativity is the most important aspect that grabs more attention to your work. Your website may hold beneficial and interesting written content for your audience, but to what extent can you hold their attention without appealing visual content? Not convinced? Here are some stats to prove:</p>

            {showMessage && <p className="section1msg">Custom shoots page is under development. Check back soon!</p>}

          </div>
        </div>
      </section>


      {/* <!---Section1 Ends----> */}


      <section className="container-fluid py-60 testimonials">
        <div className="bg-column"></div>
        <div className="row position-relative z-1">
          <div className="col-xl-6 col-md-6 col-sm-12 test1 sectpad1 testimonial-content-block">
            <img src="../static/img_new/dots.png" className="dots" />
            <h3 className="test1h3">
              Join our 200,000+ <br />
              strong photographers <br />
              community
            </h3>
            <p className="test1p">
              What looked like a small patch of purple grass, above five feet square, was moving across the sand in their direction. When it came near enough he perceived.
            </p>
            <button
              className="custom-btn"
              onClick={() => window.open("https://upload.picxy.com/login/", "_blank")}
            >
              Upload on Picxy
            </button>
          </div>
          <div className="test1 car col-xl-6 col-md-6 col-sm-12">
            <div id="testimonial-slider" className="owl-carousel">
              {testimonials.map((testimonial, index) => (
                <div className="testimonial-card" key={index}>
                  <img src="../static/img_new/quote.svg" style={{ width: '40px', height: '40px' }} />
                  <p className="description">
                    {testimonial.message}
                  </p>
                  <div className="d-flex align-items-center">
                    <div className='me-2'>
                      <img src={testimonial.img} className="user" alt={testimonial.name} />
                    </div>
                    <div>
                      <h3 className="name">{testimonial.name}</h3>
                      <p className="designation">{testimonial.designation ? testimonial.designation : 'Photographer'}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      {/* <!---testimonial Ends----> */}

      {/* <!---PhotoCollection Starts----> */}
      <section className="container-fluid section pb-90">
        <h3 class="main-title">Quick Access for More Photo Collections</h3>

        <div className='marquee marquee1'>
          <div className='marquee__inner'>
            {alphabet?.alphabet_collections?.ai?.map((AI, index) => {
              return (
                <Link as={`/collections/${AI.slug}`} href={"/collections?collection_slug=" + AI.slug} key={index}>

                  <div
                    style={{
                      backgroundImage: `url(${AI.preview_photo.thumbnail_large})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      cursor: "pointer"
                    }}
                  >
                    <p className="imgtext">{AI.slug}</p>

                  </div>

                </Link>
              );
            })}
          </div>
        </div>

        <div className='marquee marquee2 my-3'>
          <div className='marquee__inner'>
            {alphabet?.alphabet_collections?.jr?.map((JR, index) => {
              return (
                <Link as={`/collections/${JR.slug}`} href={"/collections?collection_slug=" + JR.slug} key={index}>
                  <div
                    style={{
                      backgroundImage: `url(${JR.preview_photo.thumbnail_large})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      cursor: "pointer"
                    }}
                  >
                    <p className="imgtext2">{JR.slug}</p>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>


        <div className='marquee marquee1'>
          <div className='marquee__inner'>
            {alphabet?.alphabet_collections?.sz?.map((SZ, index) => {
              return (
                <Link as={`/collections/${SZ.slug}`} href={"/collections?collection_slug=" + SZ.slug} key={index}>
                  <div
                    style={{
                      backgroundImage: `url(${SZ.preview_photo.thumbnail_large})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      cursor: "pointer"
                    }}
                  >
                    <p className="imgtext2">{SZ.slug}</p>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>


        <div class="text-center my-4"><a class="btn btn-primary btn-primary btn-rounded" title="Explore hand curated image collections" href="/collections">Explore More Collections</a></div>
      </section>
      {/* <!---PhotoCollection Ends----> */}



      {/* <!---Client logos Starts----> */}
      <section className="container clientlogo">
        <h3 className="clientlogoh3">Our Happy Customers</h3>
        {/* <Clients /> */}

        <div className="col-12">
          <div id="clients-slider" className="owl-carousel">
            <div className="block-1">
              <img src="https://images.picxy.com/clients/google-logo.png" />
              <img src="https://images.picxy.com/clients/Ogilvy@2x.jpg" />
              <img src="https://images.picxy.com/clients/Thenewsminute@2x.jpg" />
              <img src="https://images.picxy.com/clients/Pocketaces@2x.jpg" />
              <img src="https://images.picxy.com/clients/Schbang@2x.jpg" />
              <img src="https://images.picxy.com/clients/giveindia@2x.jpg" />
              <img src="https://images.picxy.com/clients/Manmatters@2x.jpg" />
              <img src="https://images.picxy.com/clients/Techstars@2x.jpg" />
              <img src="https://images.picxy.com/clients/3one4capital@2x.jpg" />
              <img src="https://images.picxy.com/clients/Accel@2x.jpg" />
              <img src="https://images.picxy.com/clients/Thebetterindia@2x.jpg" />
              <img src="https://images.picxy.com/clients/Thelogicalindian@2x.jpg" />
              <img src="https://images.picxy.com/clients/Awaaz@2x.jpg" />
              <img src="https://images.picxy.com/clients/Vahak@2x.jpg" />
              <img src="https://images.picxy.com/clients/Vahan@2x.jpg" />
              <img src="https://images.picxy.com/clients/The nudge@2x.jpg" />
            </div>


            <div className="block-1">
              <img src="https://images.picxy.com/clients/Naandi@2x.jpg" />
              <img src="https://images.picxy.com/clients/Sillymonks@2x.jpg" />
              <img src="https://images.picxy.com/clients/Arka@2x.jpg" />
              <img src="https://images.picxy.com/clients/Whitenoise@2x.jpg" />
              <img src="https://images.picxy.com/clients/Alcrowd@2x.jpg" />
              <img src="https://images.picxy.com/clients/Finwego@2x.jpg" />
              <img src="https://images.picxy.com/clients/Tide@2x.jpg" />
              <img src="https://images.picxy.com/clients/Livygo@2x.jpg" />
              <img src="https://images.picxy.com/clients/Worknetwork@2x.jpg" />
              <img src="https://images.picxy.com/clients/Deepsense@2x.jpg" />
              <img src="https://images.picxy.com/clients/Bloggersmind@2x.jpg" />
              <img src="https://images.picxy.com/clients/Vasty@2x.jpg" />
              <img src="https://images.picxy.com/clients/Sacheerome@2x.jpg" />
              <img src="https://images.picxy.com/clients/Thesis@2x.jpg" />
              <img src="https://images.picxy.com/clients/Blueowl@2x.jpg" />   
              <img src="https://images.picxy.com/clients/gobiq-logo.png" />
              </div>


          </div>
        </div>


      </section>


      {/* <section className="py-5">
        <AZ alphabet_collections={props.alphabet_collections} />
        <div className="text-center my-4">
          <Link href="/collections">
            <a className="btn btn-primary custom-btn" title="Explore hand curated image collections">Explore More Collections</a>
          </Link>
        </div>
        {/* <MorePicxy /> /}
      </section> */}




    </main>
  </LayoutBasic >
}
