import { useRef, useEffect } from "react";

export function UseHorizontalScroll(props) {
  const elRef = useRef();
  useEffect(() => {
    const el = elRef.current;
    if (el) {
      const onWheel = e => {
        e.preventDefault();
        el.scrollTo({
          left: el.scrollLeft + e.deltaY,
          // behavior: "smooth"
        });
      };
      el.addEventListener("wheel", onWheel);
      return () => el.removeEventListener("wheel", onWheel);
    }
  }, []);
  return <>
    <div ref={elRef} style={{overflowX:'hidden', whiteSpace:'nowrap'}}>
      {props.data}
    </div>
  </>
}
