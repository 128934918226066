import LayoutBasic from '../layouts/layoutBasic'
import NavPure from '../components/navPure'
import { SearchComp } from '../components/searchBar'
import {
  ASSET_URL, TOKEN, HOME_DATA_URL, PHOTOGRAPHER_VIDEOS_URL,
  GET_CONTRIBUTOR_PHOTOS, GET_CONTRIBUTOR_VIDEOS,
  PHOTOGRAPHER_PHOTOS_URL, GET_USER_URL, GET_TESTIMONIALS_URL
} from '../vars'
import { setLocalKeyValue, getLocalKeyValue } from '../storage'
import { GET } from '../helpers'
import ListPhoto from '../components/listPhoto'
import CollectionPreviewSingle from '../components/collectionPreviewSingle'
import PhotoListHolder from '../components/photoListHolder'
import axios from 'axios'
import { withRouter } from 'next/router'
import PhotographerMainProfile from '../components/photographerMainProfile'
import ContributorInfo from '../components/contributor'
import { UserPhotosPagination, UserVideosPagination } from '../components/pagination'
import Link from 'next/link'
import { TestimonialMessage, TestimonialAuthor } from '../components/testimonial'
import Error from 'next/error'
import { SeoMetaIndex, SeoMetaPhotographer } from '../components/seoMeta'
import AZ from '../components/azCollections'
import { useState } from 'react'
import Clients from '../components/clients'
import { logEvent } from '../components/analytics'
import { UseHorizontalScroll } from '../components/useScroll'
import ListVideo from '../components/listVideo'
import VideoListHolder from '../components/videoListHolder'
import Home from '../components/homePage'



class Index extends React.Component {


  constructor(props) {
    super(props)
    this.state = { testimonialIndex: 0 }
  }
  static async getInitialProps(props) {
    let token = process.browser ? getLocalKeyValue(TOKEN) : props.query.sec_token;
    let infoRequest, infoResponse = {}
    if (token) {
      try {
        // move this common query to upper level.
        infoRequest = await GET(GET_USER_URL, token)
        infoResponse = await infoRequest.data
      } catch (e) {

      }
    }

    let username
    if (props.query) {
      username = props.query.username
    }
    if (username) {


      if (props.query.type && props.query.type === "videos") {

        const VIDEOS_CONTEXT = true
        try {


          let userReq = await axios(GET_CONTRIBUTOR_VIDEOS + '?username=' + username + '&page=' + (props.query.page ? props.query.page : 1))

          let userRes = await userReq.data


          return {
            contributorVideosContext: VIDEOS_CONTEXT,
            videos: userRes.videos,
            count: userRes.count,

            likes: infoResponse.likes,

            next_page: userRes.next_page,
            previous_page: userRes.previous_page,
            total_pages: userRes.total_pages,
            current_page: userRes.page,
            contributor_info: userRes.contributor_info,
            token: token,
            user: infoResponse.user,
            cart: infoResponse.cart,
            related_collections: userRes.related_collections,
          }
        }
        catch (e) {
          if (e.response.data.error == "User not found") {
            return {
              contributorVideosContext: VIDEOS_CONTEXT,
              user_not_found: true
            }
          }
        }


      }
      else {
        const PHOTOS_CONTEXT = true
        try {

          let userReq = await axios(GET_CONTRIBUTOR_PHOTOS + '?username=' + username + '&page=' + (props.query.page ? props.query.page : 1))
          let userRes = await userReq.data


          return {
            contributorPhotosContext: PHOTOS_CONTEXT,
            photos: userRes.photos,
            count: userRes.count,

            likes: infoResponse.likes,

            next_page: userRes.next_page,
            previous_page: userRes.previous_page,
            total_pages: userRes.total_pages,
            current_page: userRes.page,
            contributor_info: userRes.contributor_info,
            token: token,
            user: infoResponse.user,
            cart: infoResponse.cart,
            related_collections: userRes.related_collections,
          }
        }
        catch (e) {
          if (e.response.data.error == "User not found") {
            return {
              contributorPhotosContext: PHOTOS_CONTEXT,
              user_not_found: true
            }
          }
        }
      }



    }
    else {

      const homeDataReq = await GET(HOME_DATA_URL)
      const homeData = await homeDataReq.data
      return {
        homeContext: true,
        testimonials: homeData.testimonials,
        photos: homeData.uniquePhotos,
        categories: homeData.categories,
        likes: infoResponse.likes,
        featured: homeData.featured,
        collections: homeData.collections,
        alphabet_collections: homeData.alphabet_collections,
        token: token,
        requireNav: 'no',
        user: infoResponse.user,
        cart: infoResponse.cart,
        feature_collection: homeData.featured_collections
      }
    }

  }


  render() {

    let props = this.props
    let photos = props.photos
    let content
    let context_list
    let likeIds = []
    let pagination
    let contributorTabs

    if (props.likes) {
      likeIds = props.likes
    }
    let photographer
    let contributorInfo
    let homeContext = props.homeContext
    let contributorVideosContext = props.contributorVideosContext
    let contributorPhotosContext = props.contributorPhotosContext

    if (props.contributor_info) {

      photographer = props.contributor_info.contributor
      contributorInfo = <ContributorInfo contributor_info={props.contributor_info} />

      if (photographer && photographer.groups.indexOf('videographer') > -1) {

        contributorTabs = <div className="container mb-5 ">
          <div className="row justify-content-center">

            <div className="col-md-6 text-center">

              <div className="btn-group d-flex">

                <Link as={`/${photographer.username}`} href={"/?username=" + photographer.username}>
                  <a className={"btn flex-fill  btn-light border  " + (contributorPhotosContext ? "active fw-bold" : " text-muted")}
                    href={`/${photographer.username}`} title={"See photos and images of " + photographer.first_name}>
                    Photos
                  </a>
                </Link>

                <Link as={`/${photographer.username}/videos`} href={"/?type=videos&username=" + photographer.username}>
                  <a className={"btn flex-fill btn-light  border " + (contributorVideosContext ? "active fw-bold" : " text-muted")}
                    href={`/${photographer.username}/videos`} title={"See footages and video clips of " + photographer.first_name}>
                    Videos
                  </a>
                </Link>

              </div>

            </div>



          </div>

        </div>
      }

    }


    if (homeContext) {
      return <Home {...props} photographer={props.contributor_info ? props.contributor_info.contributor : null} />
    }
    else if (contributorVideosContext) {
      //videos page of contrib
      content = props.videos.map((video, id) => {
        return <ListVideo key={id} video={video} reference={this.props.router.asPath} />
      })

      context_list = <VideoListHolder>
        {content}
      </VideoListHolder>
      pagination = <UserVideosPagination username={photographer?.username} {...props} />
    }
    else {
      // photos contrib
      let friendly_url = false

      if (props.photos) {
        content = photos.map((photo, id) => {
          const liked = (likeIds.indexOf(photo.id) > -1)
          return <ListPhoto titlePrefix={"Image of "} friendly_url={friendly_url} size="medium" key={id} photo={photo} liked={liked} reference={this.props.router.asPath} />
        })
      }


      context_list = <PhotoListHolder>
        {content}
      </PhotoListHolder>

      pagination = <UserPhotosPagination username={photographer?.username} {...props} />

    }


    // END OF NEW

    return <LayoutBasic {...props} defaultNavBar={true}>
      <SeoMetaPhotographer {...props} />
      <main>

        {contributorInfo}
        {contributorTabs}
        <div className="container mb-5">

          <div className="row">
            {context_list}
          </div>
          <div className="clearfix"></div>

          <div className="row space-mt-medium">
            <div className="col text-sm-end">
              {pagination}
            </div>
          </div>

        </div>

      </main>

    </LayoutBasic>

    //   let related_collection_res = props.related_collections
    //     let related_collections_render, related_collections_render1
    //   if (related_collection_res && related_collection_res.length) {
    //     let related_collections = related_collection_res.map((col)=>{
    //       return <Link as={`/collections/${col.slug}`} href={"/collections?collection_slug="+col.slug} key={col.id}>
    //           <a className="mr-2 p-1 pr-2 mb-2 badge badge-pill badge-light border d-inline-block" title={`${col.name} Collection`} onClick={(e)=>{logEvent("Search", "Search to Collection", col.name)}}>
    //           <img src={col.preview_photo.thumbnail_small} className="img-fluid rounded-circle mr-1 " alt={col.name} width="35"/>
    //                 <span style={{color:'#55595C'}}>{col.name}</span>
    //           </a>
    //
    //         </Link>
    //     })
    //
    //     related_collections_render= <><h6>
    // <span className="p-3" onClick={this.showHideCollections}>Featured in {related_collection_res ? (related_collection_res).length:""} Collection(s) </span>
    //  </h6></>
    //     related_collections_render1= <> {related_collections} </>
    //   }




  }
  //
  // let token = process.browser ? getLocalKeyValue(TOKEN) :




}


export default withRouter(Index)
