import React, { useEffect, useState } from 'react'

import { Row, Col } from 'react-bootstrap'
import Link from 'next/link'

export default class AZ extends React.Component {


  constructor(props) {
    super(props)
    this.state = { active: 'af' }
  }


  handleTab = (e, index) => {
    e.preventDefault()
    this.setState({ active: index })
  }

  detailRender = (az, index) => {

    return az[index].map((collection, i) => {

      let text = collection.anchor_text ? collection.anchor_text : collection.name
      if (text.indexOf('images') < 0) {
        text = text + " Images"
      }

      return <Col xs={6} sm={3} key={i} className="pt-1">
        <Link as={`/collections/${collection.slug}`} href={"/collections?collection_slug=" + collection.slug}>
          <a className="small text-capitalize">
            {text}
          </a>
        </Link>
      </Col>
    })

  }

  render() {

    let az = this.props.alphabet_collections
    let af, gm, ns, tz, headStrip, title

    let details

    if (az) {

      let clsName = "px-1 py-3 border rounded-pill"
      title = <div className="mb-2 text-center">Quick Access for More Photo Collections</div>

      headStrip = <Row className="mb-2">
        <Col className="text-center">

          <a className="" href="#" onClick={(e) => { this.handleTab(e, 'af') }}>
            <div className={clsName + (this.state.active === 'af' ? ' bg-light' : "")}>
              <strong>A</strong> - <strong>F</strong>
            </div>
          </a>

        </Col>
        <Col className="text-center">
          <a className="" href="#" onClick={(e) => { this.handleTab(e, 'gm') }}>
            <div className={clsName + (this.state.active === 'gm' ? ' bg-light' : "")}>
              <strong>G</strong> - <strong>M</strong>
            </div>
          </a>
        </Col>
        <Col className="text-center">
          <a className="" href="#" onClick={(e) => { this.handleTab(e, 'ns') }}>
            <div className={clsName + (this.state.active === 'ns' ? ' bg-light' : "")}>
              <strong>N</strong> - <strong>S</strong>
            </div>
          </a>
        </Col>
        <Col className="text-center">
          <a className="" href="#" onClick={(e) => { this.handleTab(e, 'tz') }}>
            <div className={clsName + (this.state.active === 'tz' ? ' bg-light' : "")}>
              <strong>T</strong> - <strong>Z</strong>
            </div>
          </a>

        </Col>
      </Row>


      details = ['af', 'gm', 'ns', 'tz'].map((i, index) => {
        const comp = this.detailRender(az, i)
        const hide = i !== this.state.active ? 'd-none' : ''
        return <Row className="pt-3" key={i} active={this.state.active} className={hide}>
          {comp}
        </Row>
      })



    }

    return <div className="container mb-4 mt-2">
      {title}
      {headStrip}

      {details}
    </div>
  }

}
